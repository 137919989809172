import google from "../assets/google.png";
import facebook from "../assets/facebook.png";
import email from "../assets/email.svg";
import settings from "../assets/settings-navbar.svg";
import rules from "../assets/rules-navbar.svg";
import notification from "../assets/notification-navbar.svg";

// settings icon
import ChangePassword from "../assets/settings-icons/change-password.svg";
import ContactUs from "../assets/util-icons/contact.svg";
import FAQs from "../assets/settings-icons/faqs.svg";
import logout from "../assets/settings-icons/logout.svg";
import ManageSubscription from "../assets/settings-icons/manage-subscription.svg";
import Preferences from "../assets/settings-icons/preferences.svg";
import ProfileSettings from "../assets/settings-icons/profile-settings.svg";
import TermsCondition from "../assets/settings-icons/terms-condition.svg";
import Privacy from "../assets/settings-icons/privacy-policy.svg";

export const APP_CONFIG = {
  LANDING_AUTH_TAGLINE: "You are the hero of your own story",
  EMAIL_PLACEHOLDER_LOGIN: "Email",
  PASSWORD_PLACEHOLDER_LOGIN: "Please Enter Your Password",
  RESET_PASSWORD_HELPER: "We'll send yo a link to reset your password",
  DATEPICKER_LABEL: "What is your Birthday?*",
  NEW_PASSWORD_PLACEHOLDER: "New Password (8+ characters)",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm Password",
  USERNAME_PLACEHOLDER: "Enter Username",
  FIRST_NAME_PLACEHOLDER: "First name",
  LAST_NAME_PLACEHOLDER: "Last name",
  SEARCH_PLACEHOLDER: "Search by keywords",
  STREAKS_MESSAGE: "Win the Day Streak",
  DISCOVER_ANAYTICS_TEXT:
    "Try to balance your resilience scores by choosing activities that build different types",

  AVAILABLE_OAUTHS: [
    {
      name: "google",
      icon: google,
    },
    {
      name: "facebook",
      icon: facebook,
    },
  ],

  AVAILABLE_OAUTHS_SIGNUP: [
    {
      name: "email",
      icon: email,
      text: "Sign up with Email",
    },
    {
      name: "google",
      icon: google,
      text: "Sign up with Google",
    },
    {
      name: "facebook",
      icon: facebook,
      text: "Sign up with Facebook",
    },
    // {
    //   name: "apple",
    //   icon: apple,
    //   text: 'Sign up with Apple'
    // },
  ],

  NAVBAR_ICONS: [
    {
      name: "Rules",
      icon: rules,
    },
    {
      name: "Notification",
      icon: notification,
    },
    {
      name: "Settings",
      icon: settings,
    },
  ],

  SETTING_LOWER: [
    // {
    //   value: "Delete Account",
    //   icon: DeleteAccount,
    //   singleOption: true,
    //   onClick:"delete"
    // },
    {
      value: "Logout",
      icon: logout,
      singleOption: true,
      onClick: "logout",
    },
  ],

  SETTINGS: [
    {
      value: "Profile",
      icon: ProfileSettings,
      onClick: "profile",
    },
    {
      value: "Change Password",
      icon: ChangePassword,
      onClick: "changePassword",
    },
    {
      value: "Notifications",
      icon: Preferences,
      onClick: "Notifications",
    },
    {
      value: "Manage Subscription",
      icon: ManageSubscription,
      onClick: "subscription",
    },
    {
      value: "Terms & Conditions",
      icon: TermsCondition,
      onClick: "Terms&Conditions",
    },
    {
      value: "Privacy Policy",
      icon: Privacy,
      onClick: "PrivacyPolicy",
    },
    {
      value: "FAQs",
      icon: FAQs,
      onClick: "FAQs",
    },
    {
      value: "Help Center",
      icon: ContactUs,
      onClick: "ContactUs",
    },
  ],
};

export const API_BASE_URL = process.env.REACT_APP_API_BASEURL;
// export const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// export const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const CLIENT_ID =
  "549291136071-ae79sg9cmo8bhiouo6468ursmihg9m0s.apps.googleusercontent.com";
export const APP_ID = "1234977047084439";

export const PUBLISH_KEY =
  "pk_live_51LnXGFEsWZOM70bOrblioX35mkyWhrXiTxOLs5HjGxF36OKycbj4O9qWRXxucn2gr78ZhXRuB13J7dQEzu9sWpVy00662srn2z";
